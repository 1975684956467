$baseurl: "https://student.ewmplatform.com/";
//$baseurl:"https://35.223.217.167/muzzy/dashboard/"; 
//$baseurl:"http://192.168.1.32:3500/"; 
//$baseurl:"https://ewmplatform.com/dashboard/";
//$baseurl: "https://student.ewmplatform.com/";
$bold-font: "gotham-bold";
$number-font: "fredokaone";
$medium-font: "gotham-medium";

body {
    margin: 0px;
    padding: 0px;
    color: #0B329D;
}

img {
    max-width: 100%;
    height: auto;
}

h1 {
    font-family: $medium-font;
    font-weight: normal;
}

input {
    outline: none;
}

a {
    text-decoration: none;
}

// #mainapp {
//     position: relative;
//     z-index: 3;
// }
.container {
    width: 80%;
    margin: auto;
}

#header {
    position: relative;
}

#header-ic {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 12vh;
    align-items: center;
}

#paints {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 11;
    height: 12vh;
    // background: url($baseurl + "assets/header/icons/group4035.svg");
}

#logo {
    padding-top: 2%;
    position: relative;
    z-index: 2;
}

#avatar-select {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
    height: 75vh;
    max-width: 945px;
    gap: 50px;

    .avatar-select-item {


        position: relative;
        width: 260px;
        height: 260px;
        display: flex;
        justify-content: center;
        align-items: flex-end;


        transform: scale(0.95);
        transition: ease 0.4s all;

        &:hover {
            transform: scale(1);
        }


        img {

            width: 110%;
            height: 110%;
            z-index: 2;
            position: absolute;
            border-radius: 100%;
            /* position: absolute; */
            /* object-fit: contain; */
            /* background: #fff; */
            /* margin-bottom: 40px; */
            /* object-fit: contain; */
            /* object-position: top; */
            bottom: 0;
            left: 0;
        }

        .white-avatar {
            width: 90%;
            height: 90%;
            background: #fff;
            position: relative;
            top: 0px;
            left: 0px;
            border-radius: 210px;
            z-index: 1;
        }

        &:nth-child(3) {
            .white-avatar {
                // background: red;
                width: 80%;
            }
        }
    }
}

#level-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 88vh;
    position: relative;
    z-index: 1111;
}

#levels-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex: 1;



    .level-item {
        width: 30%;
        height: 30vh;
        margin: 1.5%;
        opacity: 0;
        transform: scale(0);

        background: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 21px;
        cursor: pointer;
        transform: scale(0.98);
        transition: ease 0.6s all;
        text-decoration: none;

        &:hover {
            background: #FD6AC4;
        }

        &:nth-child(1) {
            .level-number {
                color: #FD6AC4
            }
        }

        &:nth-child(2) {
            .level-number {
                color: #45EAD1
            }
        }

        &:nth-child(3) {
            .level-number {
                color: #FD756A
            }
        }

        &:nth-child(4) {
            .level-number {
                color: #F9CB2E
            }
        }

        &:nth-child(5) {
            .level-number {
                color: #976AFC
            }
        }

        &:nth-child(6) {
            .level-number {
                color: #FF9933
            }
        }

    }

    .level-number {

        background: #C4EFFF;
        border-radius: 100%;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: fredokaone;
        font-size: 70px;
        font-weight: normal;
        color: #F9CB2E;
        -webkit-text-stroke: 3px black;
    }

    .level-txt {
        margin-top: 30px;
        font-family: $bold-font;
        font-size: 1.5vw;
        color: #0B329D;
    }
}

#avatar {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: ease 0.6s all;
    transform: translate(-30vw, 0px);

    img {
        width: auto;
        height: 100%;
    }
}

.app {
    // background: #22CCF2;

}

#mainapp {
    width: 100%;
    min-height: 100vh;
    position: relative;
    z-index: 13;

    width: 86%;
    min-height: 100vh;
    padding-left: 7%;
    padding-right: 7%;

    &.gameapp {
        // background: #EAF6FC;
        padding: 0px;
        width: 100%;
    }
}

#game-container {
    display: flex;
    width: 100%;
    min-height: 100vh;
    padding-left: 8vw;
    box-sizing: border-box;
    padding-bottom: 50px;
    background: url($baseurl + "assets/progress/bottomf.png") no-repeat bottom center;
    background-size: contain;

    #vertical-menu {
        width: 8vw;
        background: #fff;
        box-shadow: 0px 0px #0B329D;
        padding: 20px 10px;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: space-between;
        display: flex;
        height: 100%;
        position: fixed;
        left: 0px;
        top: 0px;

        .vertical-vmenu-item {
            div {
                color: #0B329D;
                font-family: $number-font;
                font-size: 20px;
                text-align: center;
                margin-top: 20px;
            }
        }
    }

    #mainquestion {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        padding-top: 5vh;
        width: 100%;

        .mainquestion-levels {
            padding-bottom: 50px;
        }
    }
}

#vertical-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.vertical-vmenu-item {
    padding-bottom: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.multiple-question-data {
    display: flex;
    // gap: 20px;
    max-width: 900px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;

    .multiple-question-item {
        width: 25%;
        padding: 0 1%;
        box-sizing: border-box;
        // overflow: hidden;
        cursor: pointer;
        border: 3px solid #EAF6FC;

        &.selected {

            //  border:3px solid #000;
            .answer-img {
                border: 6px solid #F9CB2E;

            }

            &.wronganswer {
                .answer-img {
                    border: 6px solid #FC4F63;

                }
            }
        }

        &.selected.correct {

            // border:3px solid green;
            .answer-img {
                border: 6px solid #7EBE40;

            }
        }

        .answer-img {
            border-radius: 100%;
            width: 200px;
            height: 200px;
            border: 6px solid #22cbf2;
        }
    }

    .multiple-question-title {
        padding: 10px 0px 10px 0px;
        font-family: $bold-font;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: -25px;

        >div {
            font-size: 18px !important;
            background: #22cbf2;
            padding: 10px 30px;
            border-radius: 20px;
        }
    }
}

.button-check {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0px 0px;
}

.check-answer-bt {
    background: #F9CB2E;
    border: 0px;
    color: #fff;
    padding: 12px 30px;
    border-radius: 10px;
    box-shadow: 0px 5px 0px #F4AD32;
    cursor: pointer;
    font-size: 25px;
    font-family: $number-font;

    &.arrow-right {
        min-width: 200px;
        background-image: url($baseurl + "assets/progress/arrow-right.png");
        background-repeat: no-repeat;
        background-position: 90% 50%;
        padding-right: 80px;
    }
}

.question-title-container {
    text-align: center;
    padding-bottom: 20px;
    text-align: left;
}

.question-title {
    color: #0B329D;
    font-family: $medium-font;

    span {
        color: #22CBF2;
    }
}

.nodrag {
    -webkit-user-drag: none;
}

.video-intro {
    height: 60vh;
    width: 50vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 20px solid #EC2760;
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    box-shadow: 0px 10px 0px #bc1e60;

    img {
        position: absolute;
        width: 100px;
        cursor: pointer;
        z-index: 11;
    }

    video {
        border: 10px solid #ffffff;
        background: #000;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        // object-fit: cover;
    }
}

#drag-items {
    display: flex;
    gap: 30px;
    margin-top: 5vh;
    min-height: 210px;

    .drag-item {
        display: flex;
        justify-content: center;
        padding: 10px;
        border-radius: 10px;
        width: 190px;
        height: 190px;

        img {
            border: 5px solid #22CBF2;
            border-radius: 100%;

        }
    }
}

#look_write {
    width: 100%;
    max-width: 650px;

    .word-v1 {
        >div {
            font-size: 30px;
        }

        input {
            font-size: 30px;
        }
    }
}


#look_write-items-v1 {
    display: flex;
    gap: 0px;
    margin-top: 0;
    flex-direction: column;

    .look_write-item-v1 {
        display: flex;
        justify-content: flex-start;
        padding: 0px 10px;
        text-align: center;
        position: relative;
        margin-top: -3vh;

        &:first-child {
            margin-top: 0px;
        }

        &:nth-child(even) {
            flex-direction: row-reverse;
        }

        .word-v1 {
            color: #0B329D;
            font-family: $medium-font;
            width: auto;
            padding: 20px;
            top: auto;
            box-sizing: border-box;
            border-radius: 15px;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            .readable {
                padding: 0px 6px;
            }

            span {
                &.editable {
                    width: 32px;
                    border-bottom: 1px dashed #22CBF2;
                }
            }
        }

        img {
            // border: 5px solid #D3F8FF;
            border: 5px solid #22CBF2;
            border-radius: 100%;
            width: 12vh;
            height: 12vh;

        }
    }
}

#drag-items-v1 {
    display: flex;
    gap: 30px;
    margin-top: 5vh;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .drag-item-v1 {
        display: flex;
        justify-content: center;
        padding: 10px;
        border-radius: 10px;
        width: 190px;
        height: 190px;
        flex-direction: column;
        text-align: center;
        position: relative;

        .word-v1 {
            color: #0B329D;
            font-family: $medium-font;
            border: 3px solid #22CBF2;
            position: absolute;
            bottom: -80px;
            left: 0px;
            width: 100%;
            padding: 20px;
            top: auto;
            box-sizing: border-box;
            border-radius: 15px;
            box-shadow: 0px 5px 0px #22CBF2;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;

            .readable {
                padding: 0px 6px;
            }

            span {
                &.editable {
                    width: 32px;
                    border-bottom: 1px dashed #22CBF2;
                }
            }
        }

        img {
            border: 5px solid #D3F8FF;
            border-radius: 100%;
            border: 8px solid #22CBF2;
        }
    }
}

#drag-Lists {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;

    .drop-item {
        width: 180px;
        height: 180px;

        .drop-subItem {
            width: 100%;
            height: 100%;
            border: 5px dashed #22CBF2;
            background: #D3F8FF;
            border-radius: 100%;
            overflow: hidden;
        }

        .drop-title {
            color: #0B329D;
            text-align: center;
            font-size: 20px;
            font-family: $medium-font;
            padding-top: 10px;
        }
    }
}

.question-title-container {
    justify-content: space-between;
    align-items: center;
    display: flex;

    #audio-bt {
        background: #0B329D;
        border-radius: 100%;
        padding: 10px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

#complete_write {
    .button-check {
        padding-top: 110px;
    }
}

.editable-input {
    input {
        background: none;
        border: 0px;
        width: 50px;
        border-bottom: 2px dashed #B7BCD1;
        font-family: $medium-font;
        text-align: center;
        font-size: 20px;
        color: #0B329D;
        outline: none;
    }
}

.editable-input-drag {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 5%;

    // gap:5%;
    >div {
        border-bottom: 2px solid #000;

        >input {
            padding: 0px !important;
            border: 0 !important;
            margin: 0px !important;
        }
    }
}

.word-arrow {
    position: absolute;
    top: -19px;
    left: 0px;
    width: 100%;

    img {
        border: 0px !important;
        border-radius: 0px;
        transform: rotate(180deg);
    }
}

.page-wrapper {
    height: 60vh;
    width: 100%;
    position: relative;

}

.book-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.book-wrapper iframe {
    width: 100%;
    height: 100%;
    background: #fff;
    border: 10px solid #D0D3E1;
    box-sizing: border-box;
}

#book-reader-page {
    width: 100%;
    height: 100%;

}

.go-back-2 {
    position: absolute;
    right: 50px;
    transform: rotate(180deg);

    position: absolute;
    left: auto;
    top: 27vh;
    background: #22cbf2;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 11111;
}

.go-back-3 {
    left: 50px;
    transform: rotate(0deg);
    position: absolute;
    right: auto;
    top: 27vh;
    background: #22cbf2;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 11111;
}

.actions-bottom {
    position: absolute;
    bottom: -50px;
    width: 100%;
    text-align: center;
    margin: auto;

    .play-icon {
        width: 35px;
        margin: auto;
        background: #22cbf2;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
    }
}

#white-layer {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    width: 25%;

    svg {
        width: 100%;
        height: 100%;
        ;
    }
}

#read-item {
    // &.active-item {
    //     background: #22CBF2;
    //     padding-top:20px;
    //     border-radius: 30px;
    //     box-shadow: 0px 10px 0px #1bafdb;
    // }
}

.vmenu-img {
    border-radius: 30px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    background: rgba(34, 203, 242, 0.08);
    box-shadow: 0px 10px 0px rgba(34, 203, 242, 0.08);
    transition: ease 0.4s all;

    margin: auto;

    &:hover {
        box-shadow: 0px 10px 0px #1bafdb;
        background: #22CBF2;

    }

    &.active-item {
        box-shadow: 0px 10px 0px #1bafdb;
        background: #22CBF2;

    }

}

// #watch-item {
//     // &.active-item {
//         background: #22CBF2;
//         padding-top:20px;
//         border-radius: 30px;
//         box-shadow: 0px 10px 0px #1bafdb;
//         height:60px;
//         width: auto;

//     // }
// }

// #exe-item {
//     // &.active-item {
//         background: #22CBF2;
//         padding-top:20px;
//         border-radius: 30px;
//         box-shadow: 0px 10px 0px #1bafdb;
//         height:60px;
//         width: auto;
//     // }
// }
.question-title-bold {
    font-family: $bold-font;
}

.hidden-audio {
    display: none;
}

#readnames-items {
    display: flex;
    flex-wrap: wrap;

    .readnames-item {
        width: 50%;
        position: relative;

        .word-v1 {
            position: relative;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;

            >div {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: $medium-font;
            }
        }

        svg {
            width: 100%;
            height: auto;
        }
    }
}

.read-drop-list {
    display: flex;
    flex-grow: 1;
    width: 100%;
    justify-content: space-evenly;

    .read-drop-item {
        background: #22CBF2;
        border-radius: 30px 30px 100% 100%;
        padding-top: 10px;
        transition: ease 0.4s all;

        img {
            background: #22CBF2;
            border-radius: 0px 0px 100% 100%;
            position: relative;
            bottom: -10px;
            padding: 0px 5px 5px 5px;
            transition: ease 0.4s all;
        }

        .drop-item {
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 1 !important;
            font-family: $medium-font;
        }
    }
}

.read-drop-text {
    display: flex;
    flex-grow: 1;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 10vw;
    min-height: 12vh;

    .read-drop-item {
        background: #22CBF2;
        color: #fff;
        text-align: center;
        margin: auto;
        font-family: $medium-font;
        padding: 16px 10px;
        border-radius: 29px;
        width: 100%;
        margin: 10px;
        font-size: 1vw;
        max-width: 180px;
        min-width: 180px;
    }
}

#stars-list {
    display: flex;
    gap: 40px;
    align-items: flex-end;

    >div {
        &:nth-child(1) {
            // --animate-delay: 0s;
            // --animate-duration: 1s;

        }

        &:nth-child(2) {
            margin-bottom: 30px;
            // --animate-delay: 1s;
            // --animate-duration: 1s;

        }

        &:nth-child(3) {
            // --animate-delay: 2s;
            // --animate-duration: 1s;

        }

    }
}

.chara-lists {
    position: relative;
    width: 100%;
    height: 60vh;

    .chara-item {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 25%;
        cursor: pointer;

        img {
            max-width: 100%;
            height: auto;
        }

        &.item1 {
            left: 0px;
            top: 0px;
        }

        &.item2 {
            left: 25%;
            top: 10vh;
        }

        &.item3 {
            left: 50%;
            top: 0px;
        }

        &.item4 {
            left: 75%;
            top: 10vh;
        }

        &.item5 {
            left: 0;
            top: 30vh;
        }

        &.item6 {
            left: 50%;
            top: 30vh;
        }
    }

}

.sort-item {
    width: 800px;
    gap: 15px;

    >div {
        flex: 1;
    }


}

.blue-border {
    cursor: move !important;

    img {
        border: 6px solid #22CBF2;
        margin: 5px;
        box-sizing: border-box;
        border-radius: 20px;

    }
}

#complete-items-v1 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;

    .items-v1 {
        background: rgba(34, 203, 242, 0.26);
        padding: 20px;
        margin-bottom: 40px;
        color: #0B329D;
        font-family: $medium-font;
        font-size: 30px;
        display: inline-block;

        span {
            margin: 0px 5px;
        }

        input {
            height: 100%;
            background: none;
            border: 0px;
            border-bottom: 1px solid #0B329D;
            width: 60px;
            font-size: 30px;
            text-align: center;
            color: #0B329D;
            font-family: $medium-font;
        }
    }
}

.listenread-container {}

.listenread {
    &:nth-child(even) {
        .word-v1 {
            flex-direction: row-reverse;

            >div {
                img {
                    right: -18px;
                    left: auto !important;
                }
            }
        }

    }



}

#look_and_say {
    .word1 {
        transition: ease 0.5s all;
        cursor: pointer;

        &:hover {
            color: #FFAD48 // opacity: 0.4;
        }
    }

    .word2 {
        transition: ease 0.5s all;
        cursor: pointer;

        &:hover {
            color: #FFAD48;

            // opacity: 0.4;
            div {
                transition: ease 0.5s all;
                color: #FFAD48 !important;
            }
        }
    }

    .item-v1 {
        position: relative;
        margin: 40px 0px;
    }

    .look_words {
        position: absolute;
        padding: 0px 3vw;
        box-sizing: border-box;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        color: #0B329D;
        font-family: $medium-font;
        font-size: 1.5vw;
        gap: 20px;
    }

}

.look_read_write {
    #look_write-items-v1 {
        .look_write-item-v1 {
            flex-direction: row-reverse;

            &:nth-child(even) {
                flex-direction: row;
            }
        }
    }
}

.audio-de {
    width: 15px;
    height: 15px;
    background: #0B329D;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    padding: 8px;
    cursor: pointer;

    img {
        width: 100% !important;
        height: auto !important;
        border: 0px !important;
    }
}

.image-style {
    border-radius: 20px;
    border: 6px solid #22CBF2;
}

.leftside {
    border: 3px solid #22CBF2;
    box-shadow: 0px 5px 0px #22cbf2;
    color: #0B329D;
    border-radius: 15px;
    left: 0px;
    top: 0px;
    position: absolute;
    padding: 10px 30px;
    font-size: 20px;
    font-family: $medium-font;
    text-align: left;

    span {
        line-height: 30px;

        &:last-child {
            margin-right: 0px;
        }
    }
}

.rightside {
    border: 3px solid #22CBF2;
    box-shadow: 0px 5px 0px #22cbf2;
    color: #0B329D;
    border-radius: 15px;
    right: 0px;
    top: 0px;
    position: absolute;
    padding: 10px 30px;
    font-size: 20px;
    font-family: $medium-font;
    text-align: left;

    span {
        line-height: 30px;

        // margin-right:12px;
        &:last-child {
            margin-right: 0px;
        }
    }
}

.word-highlight {
    background: #F8C30D;
}

.circle-and-read {
    .text-item {
        color: #0B329D;
        font-family: $medium-font;
        margin: 0px 4px;
        font-size: 28px;
    }

    .options-item {
        display: flex;
        align-items: center;
        justify-content: center;

        div {
            padding: 0px 10px;
            font-size: 28px;
            background: #9176F9;
            color: #FFFFFF;
            padding: 10px;
            border-radius: 20px;
            padding: 12px 30px;
            margin: 10px 4px;
            box-shadow: 0px 6px 0px #8068E1;
            cursor: pointer;
            transition: ease 0.4s all;

            &:hover {
                box-shadow: 0px 6px 0px #E4B305;
                background: #F9CB2E;
            }
        }
    }
}

.circle-and-read-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.selected-answer {
    border-color: #F9CB2E !important
}



.book-container {
    width: 50vw;
    margin: auto;
    position: relative;
    text-align: center;

    .img-container {
        width: 30vw;
    }



    .leftside {
        width: 11vw;
        left: -3vw;
        background: #fff;
    }

    .rightside {
        width: 10vw;
        right: -3vw;
        background: #fff;

    }
}

.rightside-arrow {
    transform: rotate(180deg);
    left: -19px;
    right: auto;
    position: absolute;
}

.look_listen_say {

    .word1 {
        &:hover {
            color: #FFAD48 !important;
        }
    }

    .word2 {
        color: #fff;

        svg path {
            fill: #22CBF2;
            opacity: 1;
        }

        &:hover {
            color: #FFAD48 !important;
        }
    }
}

.read_names {

    .word1 {

        p {
            margin: 0;
            padding: 0px;

            span:first-child {
                font-family: $bold-font;
            }
        }

        &:hover {
            color: #FFAD48 !important;

            p {
                color: #FFAD48 !important;
            }
        }
    }

    .word2 {
        p {
            &::first-letter {
                color: red !important
            }


        }

        span:first-child {
            font-family: $bold-font;
        }

        &:hover {
            color: #FFAD48 !important;

            p {
                color: #FFAD48 !important;
            }
        }

        svg path {
            fill: #22CBF2;
            opacity: 1;
        }
    }

    .text-st0-0 {
        svg path {
            fill: #FD6AC4 !important;
            opacity: 0.4;
        }

    }

    .text-st1-0 {
        svg path {
            fill: #FD9933 !important;
            opacity: 0.4;
        }
    }

    .text-st0-1 {
        svg path {
            fill: #45EA98 !important;
            opacity: 0.4;
        }

    }

    .text-st1-1 {
        svg path {
            fill: #976AFC !important;
            opacity: 0.4;
        }
    }
}

.whatsname {
    background: #89C647;
    color: #fff;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 25px;
    font-family: $medium-font;
    transform: rotate(-2deg);
}

.read-drop-list.v2 .read-drop-item img {
    border-radius: 25px;
}

#drag-items-v1 {
    &.drag-v2 {
        .drag-item-v1 {
            width: auto;
            height: auto;

            >img {
                width: 190px;
                height: 190px;
                margin: auto;
            }

            .word-v1 {
                display: inline-block;
                position: relative;
                bottom: -20px;
            }
        }

        .editable-input input {
            width: 100px;
        }
    }

}

.emptydots {
    border-bottom: 3px dotted #22cbf2;
    display: inline-block;
    width: 14px;
}

.color-select {
    display: flex;

    div {
        border: 3px solid blue;
        width: 25px;
        height: 25px;
        border-radius: 100%;
        margin: 5px;
        cursor: pointer;
        transition: ease 0.2s all;

        &.dayclass {
            border-color: #22CBF2;

            &.selected-color {
                background: #22CBF2;
            }
        }

        &.nightclass {
            border-color: #0B329D;

            &.selected-color {
                background: #0B329D;
            }
        }
    }
}

.arrows {
    // position: absolute;
    top: 0;
    left: 0;
    padding-left: 8vw;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 11111;

    >div {
        background: green;
        color: #fff;
        padding: 10px;
        font-family: $bold-font;
        cursor: pointer;
        position: absolute;
        top: 50vh;
        left: 8vw;
        margin-left: 10px;
        z-index: 11111;

        &:last-child {
            right: 0px;
            left: auto;
            margin-right: 10px;
            margin-left: 0px;
        }
    }
}

.read-match-v2 {
    .read-drop-list .read-drop-item {
        margin: 0 15px;
    }

    .read-drop-list .read-drop-item img {
        box-sizing: border-box;
    }
}

.book-container.v2 {
    position: relative;
    min-height: 50vh;
    border-radius: 20px;
    border: 6px solid #D0D3E1;
    background: #fff;

    padding: 15px;

    .rightside {
        position: static;
        border: 0px;
        width: 65%;
        box-shadow: 0px 0px 0px;
        box-sizing: border-box;
    }

    .leftside {
        position: static;
        border: 0px;
        width: 65%;
        box-sizing: border-box;
        box-shadow: 0px 0px 0px;

    }

    >img {
        border: 0px;
        width: 35%;
        width: auto;
        position: absolute;
        bottom: 0px;
        right: 0px;
    }

    .rightside-arrow {
        display: none;
    }

    .leftiside-arrow {
        display: none;
    }

}

.dragtable .main-items {
    display: flex;
    border-radius: 20px;
    background: #D1F1F9;
    box-shadow: 0px 0px 10px #00000024;
    box-sizing: border-box;
    min-height: 80vh;
}

.dragtable .main-items>div {
    padding: 10px;
}

.dragtable .main-items .main-subitems {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.dragtable .drop-subitems {
    // background: red;
    width: 100%;
    height: 100%;
    transition: ease 0.2s all;
}

.unit-uncolor {
    background: url($baseurl + "assets/header/icons2/unit_color2.png") no-repeat center;
    background-size: contain;
    width: 44px;
    height: 61px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-family: $bold-font;
    font-size: 30px;
    padding-bottom: 5px;
    box-sizing: border-box;
    color: #E1E3EC;
    margin-right: 16px;

    &:last-child {
        margin-right: 0px;
    }

    &.rev-color {
        background: url($baseurl +  "assets/levels/level1/rev2/uncolor.png") no-repeat center;
        background-size: contain;
    }

    &.reader-color {
        background: url($baseurl +  "assets/levels/level1/rev2/read_uncolor.png") no-repeat center;
        background-size: contain;
    }

}

.unit-color {
    background: url($baseurl +  "assets/header/icons2/unit_color.png") no-repeat center;
    background-size: contain;
    width: 44px;
    height: 61px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-family: $bold-font;
    font-size: 30px;
    padding-bottom: 5px;
    box-sizing: border-box;
    color: #0B329D;
    margin-right: 16px;

    &:last-child {
        margin-right: 0px;
    }

    &.rev-color {
        background: url($baseurl +  "assets/levels/level1/rev2/color.png") no-repeat center;

        background-size: contain;
    }

    &.reader-color {
        background: url($baseurl +  "assets/levels/level1/rev2/reader.png") no-repeat center;
        background-size: contain;
    }
}

.mainTitle {
    color: #0F3177;
    font-family: $medium-font;
    font-size: 21px;
}

#checkboxs-list {
    >div {
        width: 300px;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border: 5px solid #C3BAFA;
        border-radius: 30px;
        background: #fff;

        img {
            padding: 10px;
            box-sizing: border-box;
        }
    }

    svg {
        position: absolute;
        left: -15px;
        top: -15px;
        z-index: 1111;
        background: #22cbf2;
        border-radius: 100%;
        padding: 5px;
    }

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.bluebt {
    background: #22CBF2 !important;
    color: #fff;
    border-radius: 20px;
    padding: 10px 20px;
    font-family: $medium-font;
}

.blueshadow {
    box-shadow: 0px 5px 0px #1BAFDB;
}

.mainTitleStyle {
    padding: 10px 50px !important;

    div {
        padding: 5px 0px;
    }
}


.arrow-before {
    border: 15px dashed transparent;
    border-bottom-style: solid;
    border-right-color: #7ebe40;
    position: absolute;
    width: 0;
    height: 0;
    font-size: 0;
    right: 99%;
    bottom: 42%;
}

.text-itemv {
    position: relative;
    margin-bottom: 50px;

    &:last-child {
        margin-bottom: 0px;
    }
}

.text-style {
    color: #0B329D;
    font-family: $medium-font;
    border: 4px solid #22CBF2;
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    line-height: 25px;
    width: 100%;
    font-size: 20px;

    b {
        font-family: $bold-font;
        font-weight: normal;
    }

    ol,
    ul {
        margin: 0px 20px;
        padding: 0px;

        &.without {
            list-style: none;
            list-style-image: none;
            padding-left: 0px;
            margin-left: 0px;
        }
    }

    li {
        margin-bottom: 2px;
    }
}

.number-item {
    position: absolute;
    left: -20px;
    top: -20px;
    background: #22cbf2;
    color: #fff;
    border-radius: 20px;
    font-size: 20px;
    padding: 10px;
    width: 20px;
    height: 20px;
    text-align: center;
    font-family: $medium-font;
}

.table-style1 {
    table {
        color: #000;
        font-family: $medium-font;
        font-size: 1.5vw;
        border-color: #9AE5F8;

        th {
            background: #22CBF2;
            color: #fff;
        }
    }
}

.selected-item {
    fill: #F9CB2E;
}

.correct-answer {
    fill: #7EBE40;
}

.wrong-answer {
    fill: red;
}

.rect-text {
    cursor: pointer;
}

.listen_read_svg_v3 {
    position: static;
}

tspan {
    font-family: $medium-font;
}

.table-style-v2 {
    background: #D1F1F9;

    span {
        color: #0B329D;
        font-family: $medium-font;
        font-size: 30px;
    }

    th {
        background: rgb(234, 246, 252);

        div {
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                background: rgb(34, 203, 242);
                color: rgb(255, 255, 255);
                padding: 10px 20px;
                border-radius: 40px;
                font-size: $medium-font;
                font-weight: normal;
            }
        }
    }

    td {
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        position: relative;

        &:last-child {
            border-right: 0;
        }
    }

    input {
        height: 100%;
        background: none;
        border: 0px;
        border-bottom: 1px solid #0B329D;
        width: 60px;
        font-size: 30px;
        text-align: center;
        color: #0B329D;
        font-family: "gotham-medium";
    }
}

.list-repeat {
    max-width: 1200px;

    .multiple-question-item {
        width: auto;
        min-width: 390px;
        margin-bottom: 30px;
    }

    .multiple-question-img {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    input {
        color: #fff;
        font-family: "gotham-bold";
        font-size: 18px;
    }
}

.textarea-style {

    line-height: 2ch;
    background: none;

    background-image: linear-gradient(transparent, transparent calc(2ch - 1px), #aaa 0px);
    background-size: 100% 2ch;
    resize: none;
    outline: none;
    border: 0;
    overflow: hidden;
}

.column-style {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    width: 100%;
    min-width: 900px;

    #drag-Lists {
        width: 50%;
        margin-top: 0px !important;
        flex-direction: column;

        .drop-item {
            height: auto !important;

            .drop-subItem {
                width: 13vw;
                height: 13vw;
            }
        }
    }

    #drag-items {
        width: 50%;
        flex-direction: column;
        margin-top: 0px !important;
    }
}

.listen_read_v3_messages .leftside-contain {
    flex-direction: row-reverse;
}

.listen_read_v3_messages .rightside {
    left: 34px !important;
}

.listen_read_v3_messages .leftside {
    left: -34px !important;
}

#mobile-container {
    background: url($baseurl + "assets/levels/level3/unit1/talk_friend/mobileframev2.png") repeat center;
    width: 523px;
    margin: auto;
    padding: 50px 0px;

    .book-container {
        width: auto;
    }
}

#talk-to-friend {

    .leftside {
        @media screen and (max-width: 1280px) {
            transform: scale(0.7);
            transform-origin: left center;
        }
    }

    .rightside {
        @media screen and (max-width: 1280px) {
            transform: scale(0.7);
            transform-origin: right center;
        }
    }

}

.subtut {
    bottom: -50px !important;

    .bluebt {
        min-height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

#fixed-layout #drag-items {
    position: fixed;
    bottom: 0px;
    left: auto;
    right: 0px;
    height: 100%;
    background: #D3F8FF;
    z-index: 111;
    top: 0px;
    margin-top: 0px !important;
    display: flex;
    flex-direction: column;
    width: 10vw;

    .drag-item {
        width: 7vw !important;
        height: 7vw !important;
    }
}

.question-item-op {
    background: none !important;
    box-shadow: 0px 0px 0px !important;
    color: #0B329D !important;
    margin: 0px !important;
    padding: 0px 10px !important;
}

.correct-answer-option {
    box-shadow: 0px 6px 0px #E4B305 !important;
    background: #F9CB2E !important;
}

.green-answer-option {
    box-shadow: 0px 6px 0px #608F30 !important;
    background: #7EBE40 !important;

}

.red-answer-option {
    box-shadow: 0px 6px 0px #CA0000 !important;
    background: red !important;
}

// .complete_multilines span {
//     font-size:22px !important;
// }
// .complete_multilines input {
//     font-size:22px !important;
// }
.complete_multilines .items-v1 input {
    max-width: 60vw !important;
}

@media screen and (max-width: 1700px) {

    .look_listen_say div,
    .look_listen_say b,
    .look_listen_say td {
        font-size: 20px !important;
    }
}

@media screen and (max-width:1500px) {

    .look_listen_say div,
    .look_listen_say b,
    .look_listen_say td {
        font-size: 18px !important;
    }

    .complete_multilines span {
        font-size: 20px !important;
    }

    .complete_multilines input {
        font-size: 20px !important;
    }

    .complete_multilines #complete-items-v1 img {
        // max-width: 120px !important;
    }
}

@media screen and (max-width:1300px) {
    .leftside {
        span {
            font-size: 15px;
        }
    }

    .rightside {
        span {
            font-size: 15px;
        }
    }
}

@media screen and (max-width:1260px) {

    .look_listen_say div,
    .look_listen_say b,
    .look_listen_say td {
        font-size: 15px !important;
    }

    .complete_multilines span {
        font-size: 12px !important;
    }

    .complete_multilines input {
        font-size: 12px !important;
    }

    .complete_multilines #complete-items-v1 img {
        max-width: 100px !important;
    }
}

@media screen and (max-width:1034px) {

    .look_listen_say div,
    .look_listen_say b,
    .look_listen_say td {
        font-size: 12px !important;
    }

    .complete_multilines span {
        font-size: 11px !important;
    }

    .complete_multilines input {
        font-size: 11px !important;
    }

    .complete_multilines #complete-items-v1 img {
        max-width: 70px !important;
    }
}

.journalbox-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.journalbox {
    padding: 40px;
    background: #D1F1F9;
    position: relative;
    font-size: 20px;
}

.journalbox-title {
    color: #22CBF2;
    font-family: $medium-font;
    margin-bottom: 15px;
}

.journalbox-body {
    color: #22CBF2;
    font-family: $medium-font;
}

.journalbox-body span {
    color: #227cf2;
}

.journalbox-body ul {
    margin: 0px;
    padding: 0px 17px;
}

.journalbox-top-left {
    position: absolute;
    left: 0px;
    top: 0px;
}

.journalbox-bottom-left {
    position: absolute;
    left: 0px;
    top: auto;
    bottom: -5px;
}

.journalbox-bottom-right {
    position: absolute;
    right: 0px;
    top: auto;
    bottom: -4px;
}

#tableboxes-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .tableboxes-item {
        background: #22CBF2;
        border-radius: 20px;
        margin: 0px 15px;
        padding: 10px;

        .tableboxes-subitem-container {
            background: #fff;
            color: #0B329D;
            border-radius: 20px;
            padding: 20px;
        }

        .tableboxes-title {
            font-family: $medium-font;
            text-align: center;
            color: #0B329D;
            font-size: 30px;
            padding-bottom: 15px;
            padding-top: 10px;
        }

        .tableboxes-subitem {
            font-family: $medium-font;
            font-size: 25px;
            display: flex;
            align-items: center;
            justify-content: center;

            >div {
                padding: 5px 10px;
            }
        }
    }
}

.tableStyleLv {
    background: #fff;

    td {
        transition: ease 0.4s all;
    }

    td:hover {
        color: #F8CA07;
    }
}

#avatar-select form {
    width: 100%;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: auto;
    text-align: center;
    font-family: arial;
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    box-sizing: border-box;
}

.formstyle {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 20px;

    .form-item {
        width: 100%;

        input[type='text'],
        input[type='password'] {
            width: 100%;
            padding: 20px;
            background: rgba(182, 235, 250, 0.35);
            border: 0px;
            // color:#0B329D;
            box-sizing: border-box;
            font-family: $bold-font;
            font-size: 20px;
        }

        input[type='submit'] {
            padding: 0px 10px;
            font-size: 28px;
            background: #9176F9;
            color: #FFFFFF;
            padding: 10px;
            border-radius: 20px;
            padding: 12px 30px;
            margin: 10px 4px;
            box-shadow: 0px 6px 0px #8068e1;
            cursor: pointer;
            transition: ease 0.4s all;
            border: 0px;
            font-family: $bold-font;

            &:hover {
                box-shadow: 0px 6px 0px #e4b305;
                background: #F9CB2E;
            }

        }
    }
}

.vertical-vmenu-item:first-child {
    display: none;
    visibility: hidden;
}

.score-container {
    background: #22CBF2;
    padding: 15px 44px;
    margin: 30px 0px 0px;
    box-shadow: 0px 8px 0px #1bafdb;
    border-radius: 20px;
    font-family: "gotham-bold";
    font-size: 21px;
    flex-direction: column;
}